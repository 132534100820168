import React from "react"
import Header from "../components/header";
import Footer from "../components/footer";
import "../style/layout.css"

export default function Layout({children }) {
    return (
        <React.Fragment>
            <Header/>
            <div className="container">{children}</div>
            <Footer/>
        </React.Fragment>
    )
}