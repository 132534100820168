import React from "react";
import Link from "gatsby-link";
import Index from "./search"
import "../style/header.css"
const searchIndices = [{ name: `teatak`, title: `Pages` }];

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {viewer: undefined};
    }

    componentWillMount() {
        //this.fetchUser();
    }

    componentWillReceiveProps(nextProps) {
        //this.fetchUser();
    }

    render() {
        return <div className="nav">
            <Link className="nav-brand" to="/" activeClassName="active">
                <img className="logo" src="/images/logo.png" alt="logo" />
                <span>TeaTak</span>
            </Link>
            <nav className="nav-menu">
                <Link className="nav-link"
                      to="/about/"
                      activeClassName="active"
                      partiallyActive={true}
                >
                    关于
                </Link>
                <Link className="nav-link"
                    to="/docs/"
                    activeClassName="active"
                    partiallyActive={true}
                >
                    文档
                </Link>
            </nav>
            <nav className="nav-profile">
                <Index indices={searchIndices} />
                {this.state.viewer ?  <a className="nav-link" href="https://console.teatak.com">
                    {this.state.viewer.fullName}
                </a> : <a className="nav-link" href="https://console.teatak.com">
                    登录
                </a>
                }
            </nav>
        </div>
    }
}

export default Header;
