import React from "react";
import Link from "gatsby-link";
import "../style/footer.css"

const Footer = () => {
    return (
        <div className="footer">
            <div className="copy-sections">
                <div className="tails">
                    <div className="tail">
                        <h4>About</h4>
                        <Link to="#">About</Link>
                    </div>
                    <div className="tail">
                        <h4>文档</h4>
                        <Link to="#">文档</Link>
                    </div>
                </div>
                <div className="copy-section">
                    <div className="copy">
                        <img className="logo" src="/images/logo.png" style={{ width: "32px" }} alt="logo" />
                        <p>
                            © 2021 TeaTak
                        </p>
                        <p>
                            <a href="https://beian.miit.gov.cn">京ICP备2021008820号</a>
                        </p>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Footer;
